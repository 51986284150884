import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebFooterComponent } from './web-footer/web-footer.component';
import { WebNavbarComponent } from './web-navbar/web-navbar.component';
import { WebContentComponent } from './web-content/web-content.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [WebFooterComponent, WebNavbarComponent, WebContentComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    WebFooterComponent,
    WebNavbarComponent,
    WebContentComponent
  ]
})
export class SharedModule { }

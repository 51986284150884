import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  listMenu = new BehaviorSubject(this.listMenuFunc);

  url = environment.apiUrl;
  treeData = "";
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  set listMenuFunc(value: any) {
    this.listMenu.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('listMenu', value);
  }

  get listMenuFunc() {
    return localStorage.getItem('listMenu');
  }

  saveTreeData(data) {
    this.treeData = data;
  }

  getTreeData() {
    return this.treeData;
  }


  getData(path) {
    return this.httpClient.get(this.url + path, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  postData(path, data) {
    return this.httpClient.post(this.url + path, data, {})
  }

  postDataWithoutData(path) {
    return this.httpClient.post(this.url + path, {})
  }

  // Province , Districts, SubDistricts 
  getProvinces() {
    return this.httpClient.get(`${this.url}/provinces`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getDistricts(ProvinceID: string) {
    return this.httpClient.get(`${this.url}/districts/${ProvinceID}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  getSubDistricts(DistrictID: string) {
    return this.httpClient.get(`${this.url}/subdistricts/${DistrictID}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  recordVisitWeb(WebPageID) {
    this.httpClient
      .post(`${this.url}/web_counting/record`, {
        "WebPageID": WebPageID
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .subscribe((response: any) => {
      });
  }

}

export const AppURL = {
    ForPopulation: 'for-population',
    ForBusiness: 'for-business',
    ForAdministrator: 'for-administrator',
    ZeroWaste: 'zero-waste',
    TreeFund: 'tree-fund',
    ProposalProject: 'proposal-project',
    Analysis: 'analysis',
    GreenSpace: 'green-space',
    Population: 'population',
    TreeMap: 'tree-map',
    EcoProduct: 'eco-product',
    Mission: 'mission',
    Organization: 'organization',
    LifeStyle: 'green-life-style',
};
import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpClientService } from "../http-client/http-client.service";

@Component({
  selector: "app-web-footer",
  templateUrl: "./web-footer.component.html",
  styleUrls: ["./web-footer.component.css"],
})
export class WebFooterComponent implements OnInit {
  address: string = "";
  companyName: string = "";
  phone: string = "";
  facebook: string = "";
  instagram: string = "";
  logoWhite = "";
  logoBlack = "";
  contactPerson: String = "";
  youtube: string = "";
  youtubeURL: string = "";
  url = '';

  constructor(private http: HttpClientService) {
    this.url = environment.apiUrl;
  }

  ngOnInit() {
    this.getCompanyProfile();
  }

  getCompanyProfile() {
    this.http
      .getData(`/company_profile`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.logoWhite = response.data.LogoWhite;
            this.logoBlack = response.data.LogoBlack;
            this.address = response.data.Address + ' ต.' + response.data.SubdistrictName + ' อ.' + response.data.DistrictName + ' จ.' + response.data.ProvinceName + ' ' + response.data.ZipCode;
            this.instagram = response.data.Instargram;
            this.facebook = response.data.Facebook;
            this.phone = response.data.PhoneNumber;
            this.companyName = response.data.Name;
            this.contactPerson = response.data.ContactPerson;
            this.youtube = response.data.Youtube;
            this.youtubeURL = response.data.URL;
          }
        },
        (error) => { }
      );
  }

  LinkYoutube() {
    // window.open(this.youtubeURL, '_blank');
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppURL } from "./app.url";

const routes: Routes = [
  { path: "", redirectTo: "", pathMatch: "full" },
  { path: "", loadChildren: './components/components.module#ComponentsModule' },
  // not found
  { path: "**", redirectTo: AppURL.ForPopulation },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientService } from '../http-client/http-client.service';

@Component({
  selector: 'app-web-content',
  templateUrl: './web-content.component.html',
  styleUrls: ['./web-content.component.css']
})
export class WebContentComponent implements OnInit {

  onFooter = true;
  onMenu = true;
  constructor
    (
      private http: HttpClientService,
      private router: Router
    ) {
    if (this.router.url == "/proposal-project") {
      this.onMenu = false;
    } else {
      this.onMenu = true;
    }
    if (this.router.url == "/tree-map" || this.router.url == "/zero-waste") {
      this.onFooter = false;
    } else {
      this.onFooter = true;
    }
  }

  ngOnInit() {
  }

}

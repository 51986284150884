import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AnalysisComponent } from 'src/app/components/analysis/analysis.component';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../http-client/http-client.service';
declare var $: any;

@Component({
  selector: 'app-web-navbar',
  templateUrl: './web-navbar.component.html',
  styleUrls: ['./web-navbar.component.css']
})
export class WebNavbarComponent implements OnInit {
  Logo = "";
  webAppContents = [];
  url = '';
  notBorderNav: boolean = false;

  constructor(
    private http: HttpClientService,
    private router: Router
  ) {
    this.url = environment.apiUrl;
    this.http.listMenu.subscribe((nextValue) => {
      this.webAppContents = JSON.parse(nextValue); // this will happen on every change
    });
    this.router.url === '/tree-map' ? this.notBorderNav = true : this.notBorderNav = false;
  }

  ngOnInit() {
    this.getCompanyProfile();
    this.getListMenu();
  }

  closeMaintanance() {
    $(`#maintanance`).modal('show');
  }

  getListMenu() {
    this.http
      .getData("/web_page_groups?public=1&get_sub=1")
      .subscribe(async (response: any) => {
        this.webAppContents = await response.data.data ? response.data.data : [];
        this.http.listMenuFunc = JSON.stringify(this.webAppContents);
      });
  }

  getCompanyProfile() {
    this.http
      .getData(`/company_profile`)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.Logo = response.data.LogoSpacial ? response.data.LogoSpacial : response.data.LogoNormal;
          }
        },
        (error) => { }
      );
  }

}
